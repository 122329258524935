import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import pubgHeader from "../../images/pubg.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubProceduralPubg() {
  window.open(
    "https://github.com/johannagranstroem/TNM048-Information-Visualization",
    "_blank"
  )
}

const pubgPage = () => (
  <Main>
    <SEO titel="Information Visualization" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Pubg" src={pubgHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">PUBG VIZ</p>
          <p class="project-header-text-grey">VISUALIZATION</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Languages</p>
        <p class="project-bulletpoint-text"> &#9642; JavaScript</p>
        <p class="project-bulletpoint-text"> &#9642; HTML</p>
        <p class="project-bulletpoint-text"> &#9642; CSS</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-03-10</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          The purpose of this project was to visualizing players most common
          death position in the game Playerunknown's Battlegrounds. The
          visualization was done by retrieving data from a data set with 2
          million data points. The data set was minimized with only wanted data
          visualized. The result was presented with a scatter plot on a single
          manipulable map from one of the maps from the game. The techniques
          that were used to make this visualization were{" "}
          <b>zooming and filtering</b> and <b>details on demand</b>. These
          techniques help the user to see and interact with all data.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubProceduralPubg()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default pubgPage
